import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import YujiAvatar from 'assets/reviewers/yuji.png';
import YasuhiroAvatar from 'assets/reviewers/yasuhiro.png';
import EmmanuelAvatar from 'assets/reviewers/emmanuel.png';
import ReviewerBg from 'assets/reviewer-bg.png';
import useResponsive from 'hooks/useResponsive';

const testimonials = [
  {
    name: 'Yasuhiro Yamamoto',
    role: 'Investor',
    location: 'Tokyo, Japan',
    avatar: YasuhiroAvatar,
    discovery:
      "I discovered Finablr through a friend. The founders' sincere attitudes, solid system, and attractive interest rates impressed me.",
    benefits:
      'I’ve earned 40-50% per year net income on my $150k investment, lending over $500k to about 50 borrowers. Despite repayment delays, the collateral ensures my principal and interest are secure.',
    recommendation:
      'Start with a small amount like $3,000 to see if Finablr works for you, then increase your investment once comfortable.',
  },
  {
    name: 'Yuji Nagasaki',
    role: 'Former Branch Manager',
    location: 'Yokohama-city, Kanagawa',
    work: 'MUFG BANK',
    avatar: YujiAvatar,
    discovery:
      'I was introduced to Finablr by my friend Yasu. I wanted to support the loan business in Uganda using my experience from Japan. The fact that Finablr handles negotiations and manages collateral was a significant advantage for me.',
    benefits:
      "Finablr ensures debt collection and provides regular portfolio reports. They respond accurately to lenders' questions and requests.",
    recommendation:
      "I recommend Finablr for their effective negotiation strategies and solutions that benefit both lenders and borrowers. They also keep me informed about borrowers' businesses, making me feel like I'm supporting Ugandan businesses.",
  },
  {
    name: 'San Emmanuel James',
    role: 'Entrepreneur/',
    location: 'Bio Informatation Scientist.',
    work: 'Durban, South Africa',
    avatar: EmmanuelAvatar,
    discovery:
      "I found Finablr hearing the CEO speak about it at a mutual event. The platform's unique business model sold me in.",
    benefits:
      'I’ve seen significant growth with returns averaging 45% per annum. I’ve lent over $30,000 securely thanks to collateral-backed loans.',
    recommendation: "Finablr offers excellent support and high returns. I'd highly recommend it.",
  },
];

export const Reviewers = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { isMobile } = useResponsive();

  useEffect(() => {
    const interval = setInterval(nextTestimonial, 5000); // Auto-scroll every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prev) => (prev + 2) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prev) => (prev - 2 + testimonials.length) % testimonials.length);
  };

  const displayedTestimonials = [
    testimonials[currentIndex],
    testimonials[(currentIndex + 1) % testimonials.length],
  ];

  return (
    <section className="p-4 md:p-20 mt-[24vh] md:mt-4">
      <div className="relative flex justify-center items-center h-[80vh]">
        <button
          className="hidden md:block absolute left-4 text-secondary-100 font-bold"
          onClick={prevTestimonial}
        >
          &larr;
        </button>
        <div className="grid grid-cols-1 gap-2">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
              className="text-center border-8 border-secondary-100 rounded-lg p-6 bg-white shadow-md w-full max-w-xl mb-12"
            >
              <div className="flex flex-col items-center">
                <div
                  className="w-fit p-8 h-[20vh] -mt-24"
                  style={{
                    backgroundImage: `url('${ReviewerBg}')`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  {/* <img
                    src={testimonials[currentIndex].avatar}
                    alt={testimonials[currentIndex].name}
                    className="mx-auto rounded-full border-red-500 border-4 h-32 -mt-32"
                  /> */}
                  <div className="">
                    <div className="font-bold">{testimonials[currentIndex].name}</div>
                    <div>
                      <em className="text-xs">{testimonials[currentIndex].role}</em>
                    </div>
                    <div className="font-bold text-xs">{testimonials[currentIndex].location}</div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="text-secondary-100 font-bold">Discovery and Decision:</div>
                <div className="mb-3">{testimonials[currentIndex].discovery}</div>
                <div className="text-secondary-100 font-bold">Benefits Experienced:</div>
                <div className="mb-3">{testimonials[currentIndex].benefits}</div>
                <div className="text-secondary-100 font-bold">Recommendation:</div>
                <div className="mb-3">{testimonials[currentIndex].recommendation}</div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* <AnimatePresence mode="wait">
              {displayedTestimonials.map((testimonial, idx) => (
                <motion.div
                  key={testimonial.name}
                  initial={{ opacity: 0, x: idx === 0 ? 50 : -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: idx === 0 ? -50 : 50 }}
                  transition={{ duration: 0.5 }}
                  className="text-center border-8 border-secondary-100 rounded-lg p-6 bg-white shadow-md md:w-full max-w-md mx-4"
                >
                  <div className="flex flex-col items-center">
                    <div
                      className="md:w-[28vw] p-8 h-[24vh] -mt-24"
                      style={{
                        backgroundImage: `url('${ReviewerBg}')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <img
                        src={testimonial.avatar}
                        alt={testimonial.name}
                        className="mx-auto rounded-full border-red-500 border-4 h-32 -mt-24 md:-mt-32"
                      />
                      <div className="">
                        <div className="font-bold">{testimonial.name}</div>
                        <div>
                          <em>{testimonial.role}</em>
                        </div>
                        {testimonial.work && <div className="font-bold">{testimonial.work}</div>}
                        <div className="font-bold text-[11px]">{testimonial.location}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="text-secondary-100 font-bold text-lg">
                      Discovery and Decision:
                    </div>
                    <div className="mb-3 text-sm">{testimonial.discovery}</div>
                    <div className="text-secondary-100 font-bold text-lg">
                      Benefits Experienced:
                    </div>
                    <div className="mb-3 text-sm">{testimonial.benefits}</div>
                    <div className="text-secondary-100 font-bold text-lg">Recommendation:</div>
                    <div className="mb-3 text-sm">{testimonial.recommendation}</div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence> */}
        </div>
        <button className="absolute right-4 text-secondary-100 font-bold" onClick={nextTestimonial}>
          &rarr;
        </button>
      </div>
    </section>
  );
};
