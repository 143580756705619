import React from 'react';
import BannerFooter from 'assets/banner-footer.png';
import { Facebook, Instagram, Linkedin } from 'react-feather';

export const Footer = () => {
  return (
    <footer className="">
      <section className="bg-[#FEB11B] p-4 pt-12 md:p-20">
        <div className="font-arcamajora-heavy text-3xl md:text-5xl text-center mb-2">
          How do I join Finablr?
        </div>
        <div className="text-center font-arcamajora-bold">
          Join us Today as a Borrower, Lender, or Investor.
        </div>
        <div className="flex flex-row items-center justify-center my-12">
          <button className="bg-white shadow text-xl font-arcamajora-heavy rounded-full p-1 px-2">
            Get Started
          </button>
        </div>
      </section>
      <div className="py-8 px-12 md:px-48 border-t pt-16 border-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-8">
          <div className="">
            <div className="font-arcamajora-heavy text-3xl mb-4">ABOUT FINABLR</div>
            <div className="font-arcamajora-bold text-sm mb-4">
              Finablr is a mobile network application that pre-qualifies and links borrowers secured
              by collateral to registered lenders in Uganda.
            </div>
            <div className="font-arcamajora-bold mb-4 text-sm">
              The Finablr platform is owned and managed by Enabling Finance Limited, a credit
              finance company whose foundations date as far back as 2014.
            </div>
          </div>
          <div className="">
            <div className="font-arcamajora-heavy text-3xl mb-4">GET IN TOUCH</div>
            <div className="font-arcamajora-heavy">Address</div>
            <div className="text-sm">P.O BOX, 134920 Kampala, Uganda</div>
            <div className="text-sm mb-4">
              PLOT 14051, Twin Plaza, R.60 Mbogo Road-Kira, opposite Posh Petrol Station
            </div>
            <div className="font-arcamajora-heavy">Email</div>
            <div className="mb-4 text-sm">operations@enablingfinancelimited.com</div>
            <div className="font-arcamajora-heavy">Phone</div>
            <div className="">
              <span className="font-arcamajora-heavy text-sm">Office Tel:</span> +256706400057
            </div>
            <div className="">
              <span className="font-arcamajora-heavy text-sm">Mobile Tel:</span> +256744519692
            </div>
            <div className="flex flex-row items-center space-x-2 mt-4">
              <a
                href="https://www.linkedin.com/company/finablr-efl/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gray-800 hover:bg-[#FEB11B] rounded-full flex flex-row items-center justify-center h-8 w-8"
              >
                <Linkedin height={14} className="text-white" />
              </a>
              <a
                href="https://www.facebook.com/Finablr-EFL"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gray-800 hover:bg-[#FEB11B] rounded-full flex flex-row items-center justify-center h-8 w-8"
              >
                <Facebook height={14} className="text-white" />
              </a>
              <a
                href="https://www.instagram.com/finablr_efl"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-gray-800 hover:bg-[#FEB11B] rounded-full flex flex-row items-center justify-center h-8 w-8"
              >
                <Instagram height={14} className="text-white" />
              </a>
            </div>

            <div className="">Finablr_EFL</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-start">
              <div className="font-arcamajora-heavy text-[#FEB11B] text-3xl mb-4">COMING SOON</div>
              <div className="font-arcamajora-bold text-sm text-start">
                Get funded faster! Apply for a loan in under 5 minutes. Our mobile app is launching
                soon—stay tuned for its release on your favorite app stores!
              </div>
            </div>
            <img src={BannerFooter} alt="" />
          </div>
        </div>

        <div class="relative flex items-center mb-3">
          <div class="w-full h-0.5 bg-gradient-to-r from-transparent via-primary-100 to-transparent"></div>
        </div>

        <div className="text-center">
          Finablr is a product of Enabling Finance Limited. Copyright 2024 All Rights Reserved
        </div>
      </div>
    </footer>
  );
};
