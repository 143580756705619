import { WebLayout } from 'components/Layout';
import React from 'react';
import BorrowerProcess from 'assets/borrower-process.png';
import LenderProcess from 'assets/lender-process.png';
import EliteLoanImg from 'assets/elite-loan.png';
import BuyOutImg from 'assets/buy-out.png';
import useResponsive from 'hooks/useResponsive';
import BorrowingProgress from '../components/BorrowingProcess';
import LendingProcess from '../components/LendingProcess';
import CollateralHeaderBg from 'assets/collateral-header-bg.svg';
import HomeScreenImg from 'assets/homescreen.png';
import CostInvolvedImg from 'assets/cost-involved.png';
import ChargesFeesImg from 'assets/charges-fees.png';
import { TermsAccordion } from '../components/TermsAccordion';

export const Finablr = () => {
  const { isMobile, isTablet, isDesktop, isPortrait, isLandscape } = useResponsive();

  const items = [
    {
      title: 'Terms of Use',
      content:
        'Please read these terms and conditions carefully as they contain important information about your legal rights, remedies, and obligations.',
    },
    {
      title: 'General Credit Policy',
      content:
        'Contained here is information on eligibility requirements, interest rates, borrowing limits, and a lot more basic facts that should help users better prepare themselves to interact with Finablr.',
    },
    { title: 'Finablr Crash Course', content: 'Content for Finablr Crash Course' },
    { title: 'General Terms of Use', content: 'Content for General Terms of Use' },
    { title: 'Lender Specific Terms', content: 'Content for Lender Specific Terms' },
    { title: 'Borrower Specific Terms', content: 'Content for Borrower Specific Terms' },
    { title: 'Claim Application Form', content: 'Content for Claim Application Form' },
  ];

  return (
    <WebLayout>
      <div className="bg-secondary-100 p-4 md:p-12 mt-8 mb-8">
        <div className="text-7xl font-arcamajora-heavy text-center text-white mb-2">Finablr</div>
        <div className="flex flex-col items-center justify-center">
          <div className="md:w-[40vw] font-louis-george text-lg text-center mb-3">
            Finablr is a mobile network application that pre-qualifies and links borrowers secured
            by collateral to registered lenders in Uganda.
          </div>
          <div className="md:w-[40vw] font-louis-george text-lg text-center">
            The Finablr platform is owned and managed by Enabling Finance Limited, a credit finance
            company whose foundations date as far back as 2014.
          </div>
        </div>
      </div>
      <div id="borrowing-process" className="p-4 md:p-12 mt-4 mb-3">
        <div className="hidden md:block absolute top-[100vh]">
          <img src={EliteLoanImg} alt="" className=" md:h-[60vh]" />
        </div>
        <div className="mx-auto bg-secondary-100 shadow rounded-md w-fit text-2xl text-white font-arcamajora-heavy p-1 mb-3">
          The Borrowing Process
        </div>
        <div className="font-louis-george text-lg text-center mx-2 md:mx-auto md:w-1/2">
          You can become a part of the Finablr network as a borrower by following the simple steps
          below:
        </div>
        <div className="">{isMobile && <BorrowingProgress />}</div>
      </div>
      <div className="">
        {isDesktop && (
          <img
            src={BorrowerProcess}
            alt="borrower process"
            className="md:-mt-12 md:h-[100vh] mx-auto"
          />
        )}
      </div>
      <div id="lending-process" className="p-4 md:p-12 mb-4">
        <div className="hidden md:block absolute top-[200vh] right-12">
          <img src={BuyOutImg} alt="" className="h- md:h-[50vh]" />
        </div>
        <div className="mx-auto bg-primary-100 shadow rounded-md w-fit text-2xl text-white font-arcamajora-heavy p-1 mb-3">
          The Lending Process
        </div>
        <div className="font-louis-george text-lg text-center mx-2 md:mx-auto md:w-1/2">
          You can become a part of the Finablr network as a lender by following the simple steps
          below:
        </div>
        <div className="">{isMobile && <LendingProcess />}</div>
        <div className="">
          {isDesktop && (
            <img src={LenderProcess} alt="lender process" className="md:-mb-12 h-[100vh] mx-auto" />
          )}
        </div>
      </div>

      <div id="collateral-security" className="mb-12 mx-6">
        <div className="mb-6 bg-black rounded-md text-white font-arcamajora-heavy p-2 w-fit mx-auto text-2xl">
          Collateral/Security
        </div>
        <div className="mb-6 font-louis-george text-lg text-center md:w-1/2 mx-auto">
          Collateral is a key component of the Finablr system. Here are a few things we think might
          be important for users to note.
        </div>
        <div className="text-center font-louis-george text-lg md:w-1/2 mx-auto">
          While Finablr is ever-evolving to include as many other marketable collateral categories
          as feasibly possible, here are the currently accepted ones:
        </div>

        <div className="mt-12">
          <div className="max-w-screen-lg mx-auto px-4 py-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-24 md:gap-6">
              {/* Electronics */}
              <div className="bg-white p-3 rounded-lg shadow-2xl">
                <div className="border-2 border-secondary-100 rounded-lg p-4 md:h-[45vh]">
                  <div
                    className="text-white text-center text-xl font-arcamajora-heavy p-6 h-[12vh] -mt-14"
                    style={{
                      backgroundImage: `url('${CollateralHeaderBg}')`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    Electronics:
                  </div>
                  <ul className="list-disc pl-5 font-louis-george text-lg mt-4 space-y-1">
                    <li>Pictures of device</li>
                    <li>Functionality Assessment/valuation report</li>
                  </ul>
                </div>
              </div>

              {/* Vehicles */}
              <div className="bg-white p-3 rounded-lg shadow-2xl">
                <div className="border-2 border-secondary-100 rounded-lg p-4 md:h-[45vh]">
                  <div
                    className="text-white text-center text-xl font-arcamajora-heavy p-6 h-[12vh] -mt-14"
                    style={{
                      backgroundImage: `url('${CollateralHeaderBg}')`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    Vehicles:
                  </div>
                  <ul className="list-disc pl-5 font-louis-george text-lg mt-4 space-y-1">
                    <li>Copy of Logbook</li>
                    <li>Pictures of vehicle</li>
                    <li>Mechanical Assessment/valuation report</li>
                    <li>Tracker</li>
                    <li>Comprehensive insurance</li>
                    <li>Caveat placement</li>
                    <li>Guarantor</li>
                  </ul>
                </div>
              </div>

              {/* Real Estate */}
              <div className="bg-white p-3 rounded-lg shadow-2xl">
                <div className="border-2 border-secondary-100 rounded-lg p-4 md:h-[45vh]">
                  <div
                    className="text-white text-center text-xl font-arcamajora-heavy p-6 h-[12vh] -mt-14"
                    style={{
                      backgroundImage: `url('${CollateralHeaderBg}')`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    Real estate:
                  </div>
                  <ul className="list-disc pl-5 font-louis-george text-lg mt-4 space-y-1">
                    <li>Pictures of property</li>
                    <li>Copy of title</li>
                    <li>Boundary opening report</li>
                    <li>Title search report</li>
                    <li>Valuation report</li>
                    <li>Mortgage placement</li>
                    <li>Spousal consent</li>
                    <li>LCI introduction</li>
                    <li>Guarantor</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <img src={HomeScreenImg} alt="" className="hidden md:block absolute md:h-[50vh]" />
        <div className="text-center text-2xl md:text-4xl font-arcamajora-heavy mb-6">
          Costs involved
        </div>
        <div className="mx-8 md:w-1/2 text-center font-louis-george text-lg md:mx-auto">
          While pre-qualification/assessment charges under Finablr vary from collateral category to
          category and on the technicalities that each case presents, here are the general cost
          ranges to consider:
        </div>
      </div>
      <div className="mb-12 p-8 md:p-12">
        <img src={CostInvolvedImg} alt="" className="mx-auto md:w-[50vw]" />
      </div>
      <div className="mb-4">
        <div className="text-center text-2xl md:text-4xl font-arcamajora-heavy mb-6">
          Loan Processing Time
        </div>
        <div className="mx-8 md:w-1/2 text-center font-louis-george text-lg md:mx-auto">
          While pre-qualification/assessment time under Finablr vary from collateral category to
          category and on the technicalities that each case presents, here are the general cost
          ranges to consider:
        </div>
      </div>
      <img src={BuyOutImg} alt="" className="hidden md:block absolute md:h-[60vh] right-0" />
      <div className="mt-12 mb-8 md:mb-48">
        <div className=" mx-8 md:mx-56 px-4 md:px-24 py-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <div className="bg-white rounded-lg shadow-2xl">
              <div className="border-4 border-primary-100 rounded-lg p-4">
                <div
                  className="text-white text-center text-xl font-arcamajora-heavy p-6 h-[12vh] -mt-14"
                  style={{
                    backgroundImage: `url('${CollateralHeaderBg}')`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  Electronics:
                </div>
                <div className="py-5">
                  <div className="text-center font-arcamajora-heavy text-3xl">2</div>
                  <div className="text-center text-xl">Working Days</div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-2xl">
              <div className="border-4 border-primary-100 rounded-lg p-4">
                <div
                  className="text-white text-center text-xl font-arcamajora-heavy p-6 h-[12vh] -mt-14"
                  style={{
                    backgroundImage: `url('${CollateralHeaderBg}')`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  Vehicles:
                </div>
                <div className="py-5">
                  <div className="text-center font-arcamajora-heavy text-3xl">3</div>
                  <div className="text-center text-xl">Working Days</div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-2xl">
              <div className="border-4 border-primary-100 rounded-lg p-4">
                <div
                  className="text-white text-center text-xl font-arcamajora-heavy p-6 h-[12vh] -mt-14"
                  style={{
                    backgroundImage: `url('${CollateralHeaderBg}')`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  Real estate:
                </div>
                <div className="py-5">
                  <div className="text-center font-arcamajora-heavy text-3xl">5-7</div>
                  <div className="text-center text-xl">Working Days</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-2xl text-secondary-100 font-arcamajora-heavy">
          <div className="">24 WORKING HOURS</div>
          <div className="">IF COLLATERAL IS PRE-REGISTERED</div>
        </div>
      </div>
      <div className="mb-4">
        {/* <img src={HomeScreenImg} alt="" className="hidden md:block absolute md:h-[40vh]" /> */}
        <div className="text-center text-2xl md:text-4xl font-arcamajora-heavy mb-6">
          Charges and Fees
        </div>
        <div className="mx-8 md:w-1/2 text-center text-xl md:mx-auto">
          Here, we elaborate upon other various costs one could expect to encounter at one point in
          their usage of the service.
        </div>
      </div>
      <div className="mb-12 p-4 md:p-12">
        <img src={ChargesFeesImg} alt="" className="mx-auto md:w-[50vw]" />
      </div>
      <div className="md:mb-24 mx-8 md:mx-48">
        <img src={HomeScreenImg} alt="" className="left-0 hidden md:block absolute md:h-[50vh]" />
        <div className="mx-8 md:mx-24 text-start text-xl md:text-3xl font-arcamajora-heavy mb-6">
          Additional Information
        </div>
        <div className="text-start font-louis-george text-lg mx-8 md:mx-24">
          <div className="mb-4">
            {' '}
            1. The lender shall incur the cost (if any) of sending cash to EFL’s collection account
          </div>
          <div className="mb-4">
            2. EFL shall incur the cost (if any) of sending lender's money from EFL’s collection
            account to borrower's account
          </div>
          <div className="mb-4">
            3. The borrower shall incur the cost of withdrawing (if any) the recieved cash from
            his/her registered account
          </div>
          <div className="mb-4">
            4. The borrower shall incur the cost (if any) of sending cash to EFL’s collection
            account in repayment
          </div>
          <div className="mb-4">
            5. EFL shall incur the cost (if any) of sending the borrower's repaid cash from EFL’s
            collection account back to the lender.
          </div>
        </div>
      </div>
      <div id="terms-and-conditions" className="bg-secondary-100 py-12">
        <div className="mx-8 md:mx-auto md:w-1/2 text-center">
          <div className="font-arcamajora-heavy text-white text-3xl md:text-5xl mb-8">
            Terms and Conditions
          </div>
          <div className="mb-3 font-louis-george text-lg">
            Please read these terms and conditions carefully as they contain important information
            about your legal rights, remedies and obligations.
          </div>
          <div className="mb-3 font-louis-george text-lg">
            By accessing or using the Finablr platform, you agree to comply with and be bound by
            these Terms and Conditions. These Terms and Conditions constitute a legally binding
            agreement between you and EFL as it governs your access to and use of the EFL (Enabling
            Finance Limited) website ap- plication, including any subdomains thereof, and any other
            websites through which EFL makes its services available, our mobile, tablet and other
            smart device applications, and application program interfaces (col- lectively termed as
            Application) and all associated services (collectively termed as EFL services). The
            Site, Application and EFL services together are herein after referred to as the Finablr
            Platform.
          </div>
          <div className="mb-3 font-louis-george text-lg">
            If you do not agree to these Terms and Conditions, you may not access or use the
            services.
          </div>
        </div>
      </div>

      <TermsAccordion items={items} />
    </WebLayout>
  );
};
