import { WebLayout } from 'components/Layout';
import React from 'react';

export const ContactUs = () => {
  return (
    <WebLayout>
      <div className="bg-secondary-100 p-4 md:p-12 mt-8 mb-8">
        <div className="text-4xl font-arcamajora-heavy md:text-7xl text-center text-white mb-2">
          Contact Us
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="w-[50vw] text-center"></div>
        </div>
      </div>
    </WebLayout>
  );
};
