import { useState } from 'react';
import { ChevronDown } from 'react-feather';

export const TermsAccordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full md:w-2/3 p-4 md:p-12 md:mx-auto">
      {items.map((item, index) => (
        <div key={index} className="border-b">
          <button
            className="w-full flex items-center py-4 text-left font-medium text-gray-800"
            onClick={() => toggleAccordion(index)}
          >
            <span
              className={`transition-transform ${openIndex === index ? 'rotate-180' : 'rotate-0'}`}
            >
              <ChevronDown className="text-secondary-100 h-4" />
            </span>
            <span className="font-arcamajora-heavy capitalize text-md">
              {item.title.charAt(0).toUpperCase() + item.title.slice(1).toLowerCase()}
            </span>
          </button>
          {openIndex === index && (
            <div className="py-2 font-louis-george text-lg text-gray-600 pb-8">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};
