import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import { MdClose } from 'react-icons/md';
import { Menu } from 'react-feather';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const LinkItems = [
    { title: 'Home', link: '/' },
    { title: 'Finablr', link: '/finablr' },
    { title: 'Our Products', link: '/our-products' },
    { title: 'About Us', link: '/about-us' },
    { title: 'Blog', link: '/blog' },
    { title: 'Contact Us', link: '/contact-us' },
    { title: 'FAQs', link: '/faqs' },
  ];

  const dropdownItems = {
    'Our Products': [
      { title: 'Finablr Pre-registered Elite Loan', link: '#finablr-pre-registered' },
      { title: 'Finablr Loan Buy-out product', link: '#finablr-loan-buyout' },
      { title: 'Finablr For Money Lender', link: '#finablr-money-lender' },
    ],
    Finablr: [
      { title: 'The Borrowing Process', link: '#borrowing-process' },
      { title: 'The Lending Process', link: '#lending-process' },
      { title: 'Collateral/ Security', link: '#collateral-security' },
      { title: 'Terms and Conditions', link: '#terms-and-conditions' },
    ],
  };

  const LinkItem = ({ title, link }) => {
    const isActive = location.pathname === link;
    return (
      <Link
        to={link}
        className={`my-1 text-lg font-medium md:mx-4 md:my-0 hover:text-secondary-500 ${
          isActive ? 'font-arcamajora-heavy text-secondary-100' : 'text-gray-700'
        }`}
      >
        {title}
      </Link>
    );
  };

  const DropdownMenu = ({ title }) => (
    <div
      className={`absolute top-full left-1/2 transform -translate-x-1/2 md:left-0 md:transform-none bg-white border border-gray-300 shadow-md w-64 rounded-md p-2 mt-1 transition-all duration-300 ease-in-out ${
        activeDropdown === title
          ? 'opacity-100 scale-100'
          : 'opacity-0 scale-95 pointer-events-none'
      }`}
      onMouseLeave={() => setActiveDropdown(null)}
    >
      {dropdownItems[title].map((item, index) => (
        <a
          key={index}
          href={`${item.link}`}
          className="block px-4 py-2 text-gray-700 hover:text-secondary-100"
        >
          {item.title}
        </a>
      ))}
    </div>
  );

  return (
    <nav className="font-arcamajora-bold px-3 md:px-42 py-2">
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="flex justify-between space-x-8 items-center">
          <div>
            <Logo />
          </div>
          <div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none md:hidden"
            >
              {isOpen ? (
                <MdClose className="h-6 w-6 fill-current" />
              ) : (
                <Menu className="h-6 w-6 fill-current" />
              )}
            </button>
          </div>
        </div>

        <div
          className={`md:flex items-center border-b border-secondary-100 ${isOpen ? '' : 'hidden'}`}
        >
          <div className="flex flex-col items-center md:space-x-10 md:flex-row md:mx-6">
            {LinkItems.map((item, index) =>
              dropdownItems[item.title] ? (
                <div
                  key={index}
                  className="relative z-50"
                  onMouseEnter={() => setActiveDropdown(item.title)}
                >
                  <Link
                    to={item.link}
                    className="my-1 text-lg font-medium md:mx-4 md:my-0 cursor-pointer hover:text-secondary-500"
                  >
                    {item.title}
                  </Link>
                  <DropdownMenu title={item.title} />
                </div>
              ) : (
                <LinkItem key={index} title={item.title} link={item.link} />
              )
            )}
            <div className="flex flex-col items-center md:space-x-10 md:flex-row md:mx-6">
              <a
                href="https://account.finablr.app/auth/register"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-primary-100 p-1 px-3 font-bold"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
