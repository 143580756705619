import {
  AboutUs,
  Blog,
  BorrowerPolicy,
  ContactUs,
  Error404Page,
  FAQs,
  Finablr,
  GeneralPolicy,
  Landing,
  LenderPolicy,
  OurProducts,
  TermsAndConditions,
} from 'features/misc';
import { useRoutes } from 'react-router-dom';

export const AppRoutes = () => {
  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/faqs', element: <FAQs /> },
    { path: '/our-products', element: <OurProducts /> },
    { path: '/finablr', element: <Finablr /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/blog', element: <Blog /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },
    { path: '/lender-policy', element: <LenderPolicy /> },
    { path: '/borrower-policy', element: <BorrowerPolicy /> },
    { path: '/general-policy', element: <GeneralPolicy /> },
    { path: '*', element: <Error404Page /> },
  ];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
