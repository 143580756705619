import { Button, Spinner } from 'components/Elements';
import { Notifications } from 'components/Notifications/Notifications';
import { queryClient } from 'lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import BrokenLampLight from 'assets/broken_lamplight.svg';

const ErrorFallback = () => {
  return (
    <div
      className=" flex flex-col items-center justify-center min-h-screen bg-gray-50 px-6 py-3"
      role="alert"
    >
      <img src={BrokenLampLight} alt="broken lamp" className="w-40 h-40 mb-2 animate-pulse" />
      <h2 className="text-2xl font-bold text-gray-800 mb-2 font-arcamajora-bold">
        Oops! Something went wrong
      </h2>
      <p className="text-gray-600 text-center mb-6">
        An unexpected error occurred. Please try refreshing the page or contact our support team if
        the issue persists.
      </p>
      <div className="flex space-x-4">
        <Button
          onClick={() => {
            localStorage.clear();
            window.location.assign(window.location.origin);
          }}
        >
          Refresh Page
        </Button>
        <Button
          variant="outline"
          onClick={() =>
            window.location.assign(
              'mailto:jonahgeek@gmail.com?subject=Issue Report&body=Describe your issue here...'
            )
          }
        >
          Report Issue
        </Button>
      </div>
    </div>
  );
};

const LoadingFallback = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
    <Spinner size="xl" />
    <p className="mt-4 text-gray-600">Loading application... Please wait.</p>
  </div>
);

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <>
              <Router>{children}</Router>
            </>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
