import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';

import { useNavigate } from 'react-router';
import { ReactComponent as NotFoundIllustration } from 'assets/error404.svg';

export const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <WebLayout>
      <Head title="Page Not Found" description="Oops! The page you're looking for doesn't exist." />
      <div className="flex flex-col items-center justify-center py-16 bg-gray-100 text-center px-4">
        <div className="max-w-md">
          <div className="flex flex-row justify-center items-center">
            <NotFoundIllustration className="h-64 mb-6" alt="404 Illustration" />
          </div>
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Page Not Found</h1>
          <p className="text-gray-600 mb-6">
            The page you’re looking for doesn’t exist or has been moved. Please check the URL or
            return to the homepage.
          </p>
          <Button
            onClick={() => navigate('/')}
            className="mx-auto px-6 py-3 text-white bg-blue-600 rounded-md shadow-lg hover:bg-blue-700"
          >
            Go Back Home
          </Button>
        </div>
      </div>
    </WebLayout>
  );
};
