import React from 'react';

const LendingProcess = () => {
  return (
    <div id="lendingProcess" className="flex flex-col px-4 md:px-16 lg:px-32 py-2">
      <div className="w-full md:max-w-4xl">
        {[
          {
            step: 'Step 1:',
            text: 'Visit the Finablr app on Finablr.app',
          },
          {
            step: 'Step 2:',
            text: 'Sign-up/Register your account by submitting your basic ‘KYC’ information.',
          },
          {
            step: 'Step 3:',
            text: 'Complete your profile by selecting your lending preferences such as loan limits, interest limits, term limits etc',
          },
          {
            step: 'Step 4:',
            text: 'You’re all done; now just sit back and relax and wait for loan requests from Finablr borrowers.',
          },
          {
            step: 'Step 5:',
            text: 'Study and select the deal that interests you by either accepting it as is, or making a counter offer with the terms that suit you.',
          },
          {
            step: 'Step 6:',
            text: 'Upon matching yourself with a Finablr borrower, contract e-signing comes next.',
          },
          {
            step: 'Step 7:',
            text: 'Once e-signing is concluded, remittance of the loan funds to EFL becomes due.',
          },
          {
            step: 'Step 8:',
            text: 'Repayments commence in accordance with the repayment schedule. Repayments are made less the charges due.',
          },
        ].map(({ step, text }, index) => (
          <div key={index} className="grid grid-cols-1 gap-4 py-2">
            <div className="flex flex-col justify-start">
              <h3 className="font-arcamajora-heavy text-lg md:text-xl font-bold text-primary-100">
                {step}
              </h3>
              <p className="font-louis-george text-lg text-black">{text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LendingProcess;
