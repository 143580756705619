import { useState } from 'react';
import WhyChooseFinablrImg from 'assets/why-choose-finablr.png';

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className="border-b">
      <button
        className="w-full text-left py-4 px-4 font-arcamajora-bold text-md border-l-4 border-[#FEB11B] focus:outline-none"
        onClick={onClick}
      >
        {title}
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className="px-4 pb-4">{content}</div>
      </div>
    </div>
  );
};

export const WhyChooseFinablr = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const accordionData = [
    {
      title: 'Smart and User-Friendly Design',
      content:
        'Simple, organized, and transparent system with a smart, user-friendly design for seamless lending and borrowing.',
    },
    {
      title: 'Flexible Borrower-Initiated Rates',
      content: 'Borrower-initiated interest rates, allowing negotiation for favorable terms.',
    },
    {
      title: 'Guaranteed Collateral Safety',
      content:
        'Collateral safety guaranteed by EFL, ensuring trust and security for both parties. It also provides an alternative income source for registered money lenders.',
    },
  ];

  return (
    <section className="p-4 md:px-56">
      <div className="grid grid-cols-1 items-center md:grid-cols-2 gap-4">
        <div>
          <div className="font-arcamajora-heavy text-4xl md:text-6xl">
            Why choose <br />
            Finablr?
          </div>
          <div className="pt-8">
            {accordionData.map((item, index) => (
              <AccordionItem
                key={index}
                title={item.title}
                content={item.content}
                isOpen={openIndex === index}
                onClick={() => toggleAccordion(index)}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-center">
          <img src={WhyChooseFinablrImg} alt="" className="h-[60vh]" />
        </div>
      </div>
    </section>
  );
};
