import { WebLayout } from 'components/Layout';
import React from 'react';
import TransactionImg from 'assets/transaction.png';
import BannerImg from 'assets/banner.png';
import { Reviewers } from '../components/Reviewers';
import { Head } from 'components/Head';
import { ACCOUNT_APP_URL } from 'config';
import { WhyChooseFinablr } from '../components/WhyChooseFinablr';

export const Landing = () => {
  const handleBorrowMoney = () => {
    window.open(`${ACCOUNT_APP_URL}/redirect?route=borrow_money`, '_blank');
  };
  const handleLendMoney = () => {
    window.open(`${ACCOUNT_APP_URL}/redirect?route=lend_money`, '_blank');
  };
  const handleMyPortfolio = () => {
    window.open(`${ACCOUNT_APP_URL}/redirect?route=my_portfolio`, '_blank');
  };
  return (
    <WebLayout>
      <Head title="Finablr" description="Uganda's First Money Lending Tier" />
      <section className="py-3 md:py-16">
        <div className="">
          <div className="absolute right-0 top-64 md:top-40 md:right-32">
            <img src={BannerImg} alt="" className="h-48 md:h-96" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <h2 className="font-arcamajora-heavy text-4xl md:text-7xl text-center font-arca-majora font-black mb-2">
              Discover New <br /> Possibilities.
            </h2>
            <div className="flex flex-row items-center justify-center">
              <p className="font-louis-george text-lg mb-6 w-1/2 text-center">
                Enabling Finance now brings to you your ultimate solution; Finablr- a platform that
                pre-qualifies and links borrowers secured by collateral to registered lenders in
                Uganda.
              </p>
            </div>
            {/*!! change fonts to gill sans */}
            <div className="my-4 md:my-8 grid grid-cols-1 md:grid-cols-3 gap-4">
              <button
                onClick={handleBorrowMoney}
                className="font-arcamajora-bold bg-secondary-100 p-2 text-white font-bold text-2xl shadow rounded-md"
              >
                Borrow Money
              </button>
              <button
                onClick={handleLendMoney}
                className="font-arcamajora-bold bg-primary-100 p-2 text-white font-bold text-2xl shadow rounded-md"
              >
                Lend Money
              </button>
              <button
                onClick={handleMyPortfolio}
                className="font-arcamajora-bold bg-gray-900 p-2 text-white font-bold text-2xl shadow rounded-md"
              >
                My Portfolio
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="md:px-48 grid grid-cols-1 md:grid-cols-2 items-center py-3 md:py-16">
        <div className="flex flex-row items-center justify-center md:justify-start md:items-start mb-12 md:mb-0">
          <img src={TransactionImg} alt="" className="h-64 md:h-[60vh]" />
        </div>
        <div className="mx-8 md:mx-0 flex flex-col items-end">
          <div className="text-4xl md:text-7xl font-arcamajora-heavy">Introducing</div>
          <div className="mt-3 text-4xl md:text-7xl bg-[#FEB11B] w-fit font-bold">Finablr</div>
          <div className="my-3 font-louis-george">
            <div className="text-end">Uganda's first and only Tier 4</div>
            <div className="text-end">Money Lending network application</div>
          </div>
          <div className="bg-secondary-100 p-4 rounded-2xl text-white">
            <div className="text-end font-arcamajora-bold mb-3 text-lg">
              Join Hundreds of Lenders and Borrowers on Finablr!
            </div>
            <div className="text-end mb-3">
              At Finablr, we understand the need for reliable, professional, and transparent
              financial services. That's why we've created a platform that bridges the gap between
              lenders and borrowers, making borrowing and lending easier, safer, and more convenient
              than ever before.
            </div>
            <div className="text-end mb-3">
              Gone are the days of walking from door to door in pursuit of credit and still being
              left with no clear options. Finablr serves as your digital marketplace, connecting you
              to trusted money lenders without the risks associated with unregulated lending.
            </div>
            <div className="text-end mb-3">
              Discover the future of finance with Finablr—where trust meets technology.
            </div>
          </div>
        </div>
      </section>
      {/* Features Section */}
      <section className="px-8 container mx-auto py-8 md:py-16 md:px-12">
        <h2 className="text-3xl font-arcamajora-bold text-start mb-8 uppercase">How It Works</h2>
        <div className="grid md:grid-cols-4 gap-4">
          <div className="bg-[#F1F1F1] shadow-md p-6 rounded-md">
            <h3 className="text-xl font-arcamajora-bold mb-4 text-start">Application</h3>
            <ul className="list-disc list-outside text-sm">
              <li className="mb-2">⁠Register on the Finablr app and submit a loan request.</li>
              <li className="mb-2">⁠Complete KYC and collateral details.</li>
              <li className="mb-2">⁠Attend an online meeting to review loan terms.</li>
            </ul>
          </div>
          <div className="bg-[#F1F1F1] shadow-md p-6 rounded-md">
            <h3 className="text-xl font-arcamajora-bold mb-4 text-start">Due Diligence</h3>
            <ul className="list-disc list-outside text-sm">
              <li className="mb-2">Credit committee evaluates and approves applications.</li>
              <li className="mb-2">
                ⁠Conduct checks like title verification and collateral valuation.
              </li>
              <li className="mb-2">⁠Approve or disapprove based on findings.</li>
            </ul>
          </div>
          <div className="bg-[#F1F1F1] shadow-md p-6 rounded-md">
            <h3 className="text-xl font-arcamajora-bold mb-4 text-start">
              Documentation & Matching
            </h3>
            <ul className="list-disc list-outside text-sm">
              <li className="mb-2">⁠Borrower reviews loan offers.</li>
              <li className="mb-2">⁠Finalize and sign digital agreements.</li>
              <li className="mb-2">⁠Secure collateral and complete documentation.</li>
            </ul>
          </div>
          <div className="bg-[#F1F1F1] shadow-md p-6 rounded-md">
            <h3 className="text-xl font-arcamajora-bold mb-4 text-start">
              Disbursement & Repayment
            </h3>
            <ul className="list-disc list-outside text-sm">
              <li className="mb-3">⁠Loan disbursed to the borrower.</li>
              <li className="mb-3">⁠Repay as per schedule; receipts provided.</li>
              <li className="mb-3">⁠Recovery actions initiated in case of default.</li>
            </ul>
          </div>
        </div>
      </section>

      <WhyChooseFinablr />

      <section className="p-4 px-12 md:px-56 my-12 md:my-24 md:mb-24">
        <div className="border-4 border-secondary-100 rounded-2xl">
          <div className="mx-auto text-secondary-100 font-arcamajora-heavy text-md md:text-4xl mb-4 bg-white -mt-5 px-2 w-fit">
            OVER THE PAST TWO YEARS
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 mb-3">
            <div className="flex flex-col items-center">
              <div className="text-2xl md:text-4xl font-arcamajora-heavy">285</div>
              <div className="text-secondary-100">Borrowers</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-2xl md:text-4xl font-arcamajora-heavy">⁠Ugx. 4.5B</div>
              <div className="text-sm md:text-md text-secondary-100">Matched Transaction Value</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-2xl md:text-4xl font-arcamajora-heavy">74</div>
              <div className="text-sm md:text-md text-secondary-100">Lenders</div>
            </div>
          </div>
        </div>
      </section>
      <Reviewers />
    </WebLayout>
  );
};
