import { WebLayout } from 'components/Layout';
import React from 'react';

// Sample JSON list of LinkedIn post links

const posts = [
  //   {
  //     _id: 1,
  //     id: '7257024179969900544',
  //     link: 'https://www.linkedin.com/posts/finablr-efl_gitex2024-fintechug-enablingfinanceltd-activity-7257288896407896064-FziL',
  //   },
  //   {
  //     _id: 2,
  //     id: '7258010380331827200',
  //     link: 'https://www.linkedin.com/posts/finablr-efl_finablr-fintechug-enablingfinanceltd-activity-7258010511563255808-1UCM',
  //   },
  //   {
  //     _id: 3,
  //     id: '7260158290297298944',
  //     link: 'https://www.linkedin.com/posts/finablr-efl_finablr-loanbuyouts-creditfinance-activity-7260206885285339137-l82p',
  //   },
  {
    _id: 4,
    id: '7259099414235922432',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablr-homeownership-debtfreedom-activity-7259821917052801029-rw7O',
  },
  {
    _id: 5,
    id: '7259099863982768129',
    link: 'https://www.linkedin.com/posts/finablr-efl_save-your-collateral-rescue-your-loan-with-activity-7260576837653725186-Cf1w',
  },
  {
    _id: 6,
    id: '7259100157235843074',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablr-interestsavings-loanrestructure-activity-7262366135835131904-OJAe',
  },
  //   {
  //     _id: 7,
  //     id: '7261628168325763072',
  //     link: 'https://www.linkedin.com/posts/finablr-efl_loanbuyout-debtrelief-financialrelief-activity-7261628168862638081-yRLu',
  //   },
  //   {
  //     _id: 8,
  //     id: '7259807006167891969',
  //     link: 'https://www.linkedin.com/posts/finablr-efl_finablrs-loan-buy-out-involves-finablr-purchasing-activity-7259807006666985472-hiAN',
  //   },
  {
    _id: 9,
    id: '7259100429186179073',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablr-debtrelief-unexpected-activity-7263113546123649026-pDkg',
  },
  {
    _id: 10,
    id: '7259102220707581952',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablrforclubs-groupinvesting-higherreturns-activity-7267447081412292608-KRkU',
  },
  {
    _id: 11,
    id: '7259101701540880384',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablrmoneylenders-passiveincome-smartlending-activity-7265650388291231745-jlfG',
  },
  {
    _id: 12,
    id: '7259100922708934658',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablrlending-safeinvesting-highreturns-activity-7264910399932690433-IALW',
  },
  {
    _id: 13,
    id: '7259094984883064832',
    link: 'https://www.linkedin.com/posts/finablr-efl_finablrprotection-safeinvesting-lendingplatform-activity-7268175615650844672-Ht77',
  },
];

export const Blog = () => {
  return (
    <WebLayout>
      <div className="bg-secondary-100 p-4 md:p-12 mt-8 mb-8">
        {/* Blog Header */}
        <div className="text-4xl font-arcamajora-heavy md:text-7xl text-center text-white mb-6">
          Our Blog
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="w-[50vw] text-center text-white">
            Explore insights, updates, and stories directly from our LinkedIn posts!
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="px-4 md:px-48 mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 md:gap-12">
          {posts.map((post) => (
            <div
              key={post._id}
              className="bg-white shadow-lg hover:shadow-2xl rounded-3xl transition-shadow duration-300 overflow-hidden"
            >
              <a href={post.link} target="_blank" rel="noopener noreferrer">
                <div className="w-full h-auto">
                  <iframe
                    src={`https://www.linkedin.com/embed/feed/update/urn:li:share:${post.id}`}
                    title={`LinkedIn Post ${post.id}`}
                    className="w-full h-[700px] border-none rounded-3xl"
                    allowFullScreen
                  ></iframe>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </WebLayout>
  );
};
