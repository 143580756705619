import React from 'react';

const BorrowingProgress = () => {
  return (
    <div id="borrowingProcess" className="flex flex-col px-4 md:px-16 lg:px-32 py-2">
      <div className="w-full md:max-w-4xl">
        {[
          {
            step: 'Step 1:',
            text: 'Visit the Finablr app on Finablr.app',
          },
          {
            step: 'Step 2:',
            text: 'Sign-up/Register your account by submitting your basic ‘KYC’ information.',
          },
          {
            step: 'Step 3:',
            text: 'Complete your profile by submitting your collateral details such as category, specifications/features, your attached value, pictures etc.',
          },
          {
            step: 'Step 4:',
            text: 'Submit your loan request highlighting features such as amount, period, interest you are offering, repayment type etc.',
          },
          {
            step: 'Step 5:',
            text: 'Due-diligence. Await contact from EFL to schedule for the due-diligence process.',
          },
          {
            step: 'Step 6:',
            text: 'Upon completion of the due-diligence, you’re all done; now just sit back and relax and wait for offers from Finablr lenders and select the offer that best suits you.',
          },
          {
            step: 'Step 7:',
            text: 'Upon matching yourself with a Finablr lender, contract e-signing comes next.',
          },
          {
            step: 'Step 8:',
            text: 'Once e-signing is concluded, you will then be required to deliver the subject collateral into EFL custody.',
          },
          {
            step: 'Step 9:',
            text: 'Upon matching yourself with a Finablr lender, contract e-signing comes next.',
          },
        ].map(({ step, text }, index) => (
          <div key={index} className="grid grid-cols-1 gap-4 py-2">
            <div className="flex flex-col justify-start">
              <h3 className="font-arcamajora-heavy text-lg md:text-xl font-bold text-secondary-100">
                {step}
              </h3>
              <p className="font-louis-george text-lg text-black">{text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BorrowingProgress;
