import { WebLayout } from 'components/Layout';
import React from 'react';
import { TermsAccordion } from '../components/TermsAccordion';
import ChatWithTeamImg from 'assets/chat-with-team.png';

export const FAQs = () => {
  const faqItems = [
    {
      title: 'IS THERE ANY SIMILAR APP ON THE UG MARKET TO FINABLR?',
      content:
        'No. Finablr is the only application in Uganda linking pre-qualified secured loan borrowers to registered lenders.',
    },
    {
      title: 'DO I NEED COLLATERAL SECURITY TO ACCESS LOANS THROUGH FINABLR?',
      content:
        'Yes. Finablr operates strictly secured loan products; this being the only surest way to protect our lenders’ capital in the unfortunate event of complete default.',
    },
    {
      title: 'HOW DO I JOIN THE FINABLR NETWORK?',
      content:
        'Simply download the Finablr app available via Playstore (for Android users) and Appstore (for IOS users), register your account with your basic KYC and you are in.',
    },
    {
      title: 'WILL MY COLLATERAL BE SOLD IF I DEFAULT COMPLETELY ON MY LOAN?',
      content:
        'Unfortunately and very rarely so; Yes. However, the good news is Finablr operates a very transparent system where all our borrowers are taken through the entire loan process including what happens in the event of default. EFL shall only dispose of collateral in the event of complete default which is where a borrower has been in default for 67 (sixty-seven) days or more with no resolution between the client and the lender as mediated by EFL. We are happy to share that in the 9 years of existence, EFL has only disposed of collateral seven times. This goes to show the very low collateral disposal rate which we hope brings comfort to our borrowing clients under Finablr.',
    },
    {
      title: 'WHAT ARE THE INTEREST RATES ON FINABLR?',
      content:
        'This is one of the most unique features of Finablr. Finablr allows borrowers to determine the interest rates they are comfortable with and able to afford and then allows lenders to accept the loan requests as are or counter them with interest rates that they are willing to lend at.',
    },
    {
      title: 'AS A LENDER ON FINABLR IS MY LOAN PORTFOLIO GUARANTEED?',
      content:
        'No. While EFL does not guarantee repayment of loans to lenders nor lender’s portfolios under Finablr being recouped, we do guarantee the presence of collateral security that is worth twice the loan value as at the time of loan disbursement. In the event of complete default, EFL makes best effort to dispose of collateral at the highest price possible enough to recover all that is due to lenders as well as to return to borrowers any amount over and above what is due.',
    },
    {
      title: 'HOW MUCH CAN I BORROW ON FINABLR AND FOR HOW LONG?',
      content:
        'Borrowers can access through Finablr up to Ugx. 300,000,000 for up to 36 months. Finablr is ever-evolving and so we, ever, strive to modify our various lending parameters.',
    },
    {
      title: 'CAN I USE MY SALARY OR NATIONAL ID AS A SECURITY?',
      content:
        'No. For the safety of Finablr lenders’ capital, Finablr only allows assets/collateral that can be resold for cash in the Uganda market fairly easily.',
    },
    {
      title: 'AS A LENDER ALREADY IN BUSINESS WHY SHOULD I LEND THROUGH FINABLR?',
      content: `Not only will you be able to continue running your own lending business unhindered by Finablr, but through Finablr shall you also have:
      - Free access to a pool of pre-qualified borrowers.
      - Free access to an ever-evolving information management system to manage your clients from the point of application to repayment/recovery.
      - Freedom from the risks of collateral custody.
      - Assisted borrower-default recovery.
      - Free access to money-lending advisory services from EFL.`,
    },
    {
      title: 'HOW LONG DOES IT TAKE FOR MY LOAN TO BE PROCESSED ON FINABLR?',
      content: `Depending on the collateral category, Finablr loans have the following processing times:
      - Electronics: 3-6 hours
      - Vehicles: 12-36 hours
      - Real Estate: 3-5 working days.
      However, it is important to note that the times above assume there are no unforeseen circumstances surrounding the collateral that are out of the control of EFL. E.g squatters found on land, caveats on logbooks, faulty electronics etc.`,
    },
    {
      title: 'WHAT HAPPENS AFTER A LENDER HAS MADE AN OFFER TO MY LOAN REQUEST?',
      content:
        'At this stage, phase 5 kicks in which is the documentation phase. Here, the lender and borrower are availed the relevant paperwork for e-signing on the Finablr platform. Once documentation is duly signed by all parties, the borrower is then prompted to deliver the declared collateral to EFL offices after which the loan is disbursed.',
    },
    {
      title:
        'DO LENDERS AND BORROWERS HAVE TO REPORT TO OFFICE TO SIGN THE LOAN AGREEMENT OR AT ANY STAGE?',
      content:
        'No. Since Finablr documentation takes advantage of the Electronics Signature Act of Uganda 2011, unless otherwise required by EFL, parties shall typically sign contracts at their places of comfort as long as the collateral has already been delivered to EFL offices by the borrower.',
    },
    {
      title: 'WHAT HAPPENS IF I DON’T RECEIVE A LOAN OFFER?',
      content:
        'While Finablr borrowers are not guaranteed to receive loan offers from Finablr lenders, the Finablr platform has an extremely low loan-matching failure rate. To increase chances of securing loan offers, borrowers are advised to make competitive loan offers which typically entail higher interest rates or collateral with a high Loan: Value ratio.',
    },
    {
      title: 'WHAT HAPPENS IF I MISS MY LOAN REPAYMENT INSTALLMENT?',
      content:
        'A missed loan repayment installment is referred to as an event of default. The following are the Finablr recovery protocols followed in the event of default:\n' +
        '- 7 days past missed installment: Interest-free grace period.\n' +
        '- 37 days past missed installment: Demand notice 1.\n' +
        '- 67 days past missed installment: Demand notice 2/Notice of Intention to Sell Collateral.\n' +
        '- 67-88 days past missed installment: Collateral attachment period (Advertising).\n' +
        '- Past attachment period: Sale of collateral to highest bidder (borrower inclusive). Please note that any amount recovered upon sale, over and beyond, what the borrower owes is duly returned to the borrower.',
    },
    {
      title: 'WHAT HAPPENS IF I PAY EXCESS OR LESS?',
      content:
        'In the event a borrower repays less than the scheduled amount, his/her deposit is deposited to a designated EFL escrow account but not marked as paid. The deposit shall only be posted to the lender once paid in full. In the meantime, late fees shall be accounted for as if the deposit had not been paid at all. In the event a borrower repays in excess, only the scheduled amount shall be posted to the lender and the excess carried forward to the next scheduled installment. In the next scheduled installment, the borrower would therefore pay less the excess amount brought down.',
    },
    {
      title: 'WHAT HAPPENS IF MY SECURITY GETS DAMAGED WHILE IN EFL CUSTODY?',
      content:
        'EFL claims full responsibility for all borrower collateral in its custody. With the exception of damage due to natural/reasonable wear and tear/depreciation, any damages shall be the full responsibility of EFL.',
    },
    {
      title: 'WHAT HAPPENS TO THE LENDER WHEN THE BORROWER DEFAULTS?',
      content:
        'Because Finablr loan repayments are not guaranteed, lenders are required to be patient as the Finablr Recovery Protocols are executed by EFL in the event of default or complete default. During recovery, borrowers continue to accrue late fees/penalties which are payable to lenders upon collateral disposal.',
    },
    {
      title: 'WHAT HAPPENS IF I PAY AFTER THE RECOVERY PROTOCOLS HAVE STARTED?',
      content:
        'Finablr Recovery Protocols shall continue to stay in effect until the borrower has paid in full of what is due as at date.',
    },
    {
      title: 'WHAT HAPPENS IF I WANT TO PAY THE LOAN EARLY IN FULL?',
      content:
        'Early repayments are not only welcome with Finablr but are also a lawful right to every borrower in Uganda. However, early repayments attract an early repayment fee as stipulated in the loan contract.',
    },
    {
      title: 'WHAT HAPPENS IF I AM NO LONGER ABLE TO FINANCE MY LOAN OFFER AFTER ACCEPTING IT?',
      content:
        'A lender can cancel his/her loan offer at any point in the lending process. However, if a lender cancels beyond the documentation phase after signing the loan contract, he/she shall attract a cancellation fee of 1% of the intended loan amount. This shall be payable upfront or deductible from the lender’s active receivables or from his/her next disbursement. A borrower can cancel his/her loan request at any point in the borrowing process. However, if a borrower cancels beyond the documentation phase after signing the loan contract, he/she shall attract a cancellation fee of 1% of the intended loan amount. This shall be payable upfront or deductible from the lender’s active receivables or from his/her next disbursement. However, EFL may at its discretion ban from the Finablr platform users that repeatedly cancel requests/offers with no clear cause.',
    },
    {
      title:
        'WHAT HAPPENS IF THE SIGNATURE ON MY NATIONAL ID IS DIFFERENT FROM MY CURRENT NATIONAL ID?',
      content:
        'Because Finablr documentation is authenticated using e-signatures in the shape and form of user signatures as on their National IDs, where a user’s signature is different on ID as is at present, the user shall be required to sign an affidavit with the EFL legal team confirming that the two signatures belong to the same person.',
    },
    {
      title: 'CAN I BORROW WHILE I HAVE AN ALREADY RUNNING LOAN?',
      content:
        'No. All borrowing clients can have only one active loan cycle running at any given time. To secure a second loan cycle, the Finablr borrower must first clear to zero his/her active loan.',
    },
    {
      title: 'CAN I LEND TO MORE THAN ONE BORROWER?',
      content:
        'Yes. Finablr lenders can lend to as many borrowers at any given time as they would like.',
    },
  ];

  return (
    <WebLayout>
      <div className="bg-secondary-100 p-4 md:p-12 mt-8 mb-8">
        <div className="text-4xl font-arcamajora-heavy md:text-7xl text-center text-white font-arcamajora-heavy mb-2">
          Frequently <br /> Asked Questions
        </div>
      </div>
      <div className="">
        <TermsAccordion items={faqItems} />
      </div>
      {/* <div className="absolute right-0 bg-gray-100 rounded-2xl w-fit p-4">
        <div className="text-md">Can’t find what you are looking for?</div>
        <div className="text-lg">Chat with our team.</div>
        <img src={ChatWithTeamImg} className="" alt="" />
      </div> */}
    </WebLayout>
  );
};
