import { WebLayout } from 'components/Layout';
import React from 'react';
import AboutBannerImg from 'assets/about-banner.png';
import OurJourneyImg from 'assets/our-journey.png';
import HomescreenImg from 'assets/homescreen.png';
import GitexGlobalImg from 'assets/gitex-global.png';
import OperationsManagerImg from 'assets/operations-manager.png';
import LoanBuyOutImg from 'assets/buy-out.png';

export const AboutUs = () => {
  return (
    <WebLayout>
      <div className="bg-secondary-100 p-4 md:p-12 mt-8">
        <div className="text-4xl font-arcamajora-heavy md:text-7xl text-center text-white mb-2">
          Meet The Team
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="mx-4 md:w-[50vw] text-center">
            The Finablr team is comprised of passionate individuals dedicated to revolutionizing the
            borrowing and lending landscape. Get to know the visionaries driving our mission
            forward.
          </div>
        </div>
      </div>
      <div className="">
        <img src={AboutBannerImg} alt="" />
      </div>
      <div className="my-12 md:my-24 mx-4 md:mx-48 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="">
          <div className="font-arcamajora-heavy text-2xl bg-[#FEB11B] w-fit mb-8 p-1">
            Our Journey
          </div>
          <div className="mb-6">
            EFL is a Tier IV money lending institution that was incorporated in 2015 following an
            event in 2014 when the would-be Founding director/CEO dared to lend 100k to a
            non-Ugandan stranger with no collateral but rather with guarantee and reassurance of his
            roommate. What was meant to be a 1-month loan was cleared within 1 week but what truly
            inspired him to start the business was the immense gratitude this ‘client’ repaid with
            as a result of the impact of the finance. EFL is therefore driven by the desire to
            recreate that impact in the lives of masses through credit finance especially across the
            African continent.
          </div>
          <div className="">
            A Fin-tech company in nature, EFL hinges 90% of its operations on its proprietary
            innovation ‘Finablr’-a mobile network application that links pre-qualified borrowers to
            ethical registered lenders in Uganda and beyond.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-96 bg-secondary-100 shadow p-4 rounded-xl mb-4">
            <div className="text-white font-arcamajora-heavy mt-3 text-xl">Our Mission</div>
            <div className="">
              To transform 500,000,000 Africans and global citizens into Finablr lenders and/or
              borrowers by 2035.
            </div>
          </div>
          <div className="w-96 bg-secondary-100 shadow p-4 rounded-xl">
            <div className="text-white font-arcamajora-heavy mt-3 text-xl">Our Vision</div>
            <div className="">
              We envision an Africa where a strong credit finance system accelerates meaningful
              socioeconomic development to support millions of growth opportunities/ideas founded on
              trust, integrity, professionalism, accountability, and community.
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 md:mb-24">
        <div className="">
          <img src={OurJourneyImg} alt="" className="mb-3" />
          <div className="mx-2 text-sm text-center mb-4">
            Finablr Team at the Uganda-Japan (UJ) Connect Finale Event in partnership with Ministry
            of ICT and JICA receiving prize money
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 mb-12">
        <div className="mx-auto mb-4 md:mb-0">
          <img src={HomescreenImg} alt="" className="h-60 md:h-[90vh]" />
        </div>
        <div className="col-span-2">
          <img src={GitexGlobalImg} alt="" className="mb-3" />
          <div className="mx-4 text-sm text-center mb-4">
            Finablr CEO exibiting to investors at the GITEX Global 2024 Event, Dubai
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 mb-12">
        <div className="col-span-2">
          <img src={OperationsManagerImg} alt="" className="mb-3" />
          <div className="mx-4 text-sm text-center mb-4">
            Finablr Operations Manager taking clients through our services at the Business Expo
            2023, Kampala.
          </div>
        </div>
        <div className="mx-auto mt-4">
          <img src={LoanBuyOutImg} alt="" className="h-60 md:h-[90vh]" />
        </div>
      </div>
    </WebLayout>
  );
};
