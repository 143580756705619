import { WebLayout } from 'components/Layout';
import React from 'react';
import BannerImg from 'assets/our-products-banner.png';
import LoanBuyOutImg from 'assets/loan-buyout.png';
import MoneyLenderImg from 'assets/money-lender.png';
import BuyOutImg from 'assets/buy-out.png';
import EliteLoanImg from 'assets/elite-loan.png';
import HomeScreenImg from 'assets/homescreen.png';

export const OurProducts = () => {
  return (
    <WebLayout>
      <div className="bg-secondary-100 p-4 md:p-12 mt-8 mb-8">
        <div className="text-4xl font-arcamajora-heavy md:text-7xl text-center text-white mb-2">
          Our Products
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="mx-4 md:w-[50vw] font-louis-george text-lg text-center">
            At Finablr, we provide a wide range of services tailored to meet your borrowing and
            lending needs. Whether you are looking to borrow for personal expenses or invest your
            funds, we have flexible options to accommodate your requirements. Our platform is
            designed to make the borrowing and lending process simple and seamless, ensuring a
            positive experience for all our users.
          </div>
        </div>
      </div>
      <div className="hidden md:absolute top-[130vh]">
        <img src={EliteLoanImg} alt="" className="h- md:h-[60vh]" />
      </div>
      <section id="finablr-pre-registered" className="mx-8 md:mx-56">
        <img src={BannerImg} alt="" />
        <div className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="">
              <div className="font-arcamajora-heavy text-2xl bg-[#FEB11B] w-fit mb-8 p-1">
                1. Finablr Pre-registered Elite Loan.
              </div>

              <div className="">
                The Finablr Pre-registered Elite allows users to pre-register their collateral on
                our platform, where we handle due diligence, valuation, legal documentation and
                pre-qualification. This enables clients to borrow quickly, conveniently, and
                digitally from our pool of Finablr lenders whenever needed—unlike the traditional,
                time-consuming loan process. Loan terms are negotiated directly between lender and
                borrower on the app and enforced by Enabling Finance Ltd, offering a streamlined and
                efficient solution for accessing funds at the right moment.
              </div>
            </div>

            <div className="my-8 md:my-24 md:ml-24 bg-[#F1F1F1] rounded-lg shadow-md w-fit p-8">
              <div className="font-arcamajora-heavy text-2xl mb-2">Features:</div>
              <div className="">
                <em>Loan amounts:</em>
              </div>
              <div className="mb-3">Ugx. 120,000- 100,000,000</div>
              <div className="">
                <em>Interest rate:</em>
              </div>
              <div className="mb-3">Flexible (from as low as 4% p.m)</div>
              <div className="">
                <em>Loan term:</em>
              </div>
              <div className="">1-18 months</div>
            </div>
          </div>
        </div>
      </section>

      <section className="mx-8 md:mx-56">
        <img src={LoanBuyOutImg} alt="" />
      </section>

      <section id="finablr-loan-buyout" className="mx-8 md:mx-56">
        <div className="mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="">
              <div className="font-arcamajora-heavy text-2xl bg-[#FEB11B] w-fit mb-8 p-1">
                2. Finablr Loan Buy-out product
              </div>

              <div className="">
                This product offers borrowers a unique opportunity to{' '}
                <span className="font-arcamajora-heavy">
                  save their high-value collateral from being liquidated by commercial banks
                </span>
                . Through the Finablr App, we carefully vet applicants, assess their repayment
                history, and evaluate their ability to repay after a loan buy-out. Once qualified,
                they are matched with Finablr lenders who offer restructured, more favorable loan
                terms.
              </div>
            </div>

            <div className="my-8 md:my-24 md:ml-24 bg-[#F1F1F1] rounded-lg shadow-md w-fit p-8">
              <div className="font-arcamajora-heavy text-2xl mb-2">Features:</div>
              <div className="">
                <em>Loan amounts:</em>
              </div>
              <div className="mb-3">Ugx. 2,000,000- 100,000,000</div>
              <div className="">
                <em>Interest rate:</em>
              </div>
              <div className="mb-3">Flexible (from as low as 4% p.m)</div>
              <div className="">
                <em>Loan term:</em>
              </div>
              <div className="">1-18 months</div>
            </div>
          </div>
        </div>
        <img src={MoneyLenderImg} alt="" />
      </section>

      <div className="hidden md:block absolute top-[260vh] right-2">
        <img src={BuyOutImg} alt="" className="h- md:h-[50vh]" />
      </div>

      <div className="hidden md:block absolute top-[130vh]">
        <img src={EliteLoanImg} alt="" className="h- md:h-[60vh]" />
      </div>

      <section id="finablr-money-lender" className="mx-4 my-12">
        <div className="mt-4">
          <img src={HomeScreenImg} alt="" className="hidden md:block absolute md:h-[40vh]" />
          <div className="mx-8 md:mx-56">
            <div className="">
              <div className="font-arcamajora-heavy text-2xl bg-[#FEB11B] w-fit mb-8 p-1">
                3. Finablr For Money Lender
              </div>

              <div className="">
                <div className="mb-5">
                  Are you a licensed money lender, SACCO, or investment club seeking higher returns?
                  The Finablr Money Lender product offers a revolutionary way to grow your
                  investments beyond traditional savings or fixed deposit accounts.
                </div>
                <div className="mb-5 font-arcamajora-heavy">Why Choose Finablr Money Lender?</div>
                <div className="mb-5">
                  <div className="font-arcamajora-heavy">Earn Higher Returns</div>
                  <div className="">
                    Maximize your earnings with returns averaging 6% per month, far outpacing
                    conventional investment options.
                  </div>
                </div>
                <div className="mb-5">
                  <div className="font-arcamajora-heavy">Hassle-Free Borrower Management</div>
                  <div className="">
                    Leave the sourcing and vetting to us. Finablr ensures borrowers are
                    pre-qualified and credible, so you can lend with confidence.
                  </div>
                </div>
                <div className="mb-5">
                  <div className="font-arcamajora-heavy">Risk Mitigation with Default Cover</div>
                  <div className="">
                    Enjoy peace of mind knowing that EFL provides repayment cover for up to 1/3 of
                    the loan period, minimizing risks from late repayments.
                  </div>
                </div>
                <div className="mb-5">
                  <div className="font-arcamajora-heavy">Collateral-Backed Security</div>
                  <div className="">
                    All loans are secured with collateral, ensuring principal protection and making
                    your investments safer.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
};
